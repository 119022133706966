html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

/*# sourceMappingURL=index.b658693c.css.map */
